import React from "react"
import PropTypes from "prop-types"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"

const EmptyPage = () => {
  const title = "Empty Page"
  const breadcrumb = { page: { title }, slug: "EXAMPLE" }

  return (
    <Page
      title={title}
      header={<Header />}
      headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
      footer={<Footer />}
    />
  )
}

EmptyPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default EmptyPage
